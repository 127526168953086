export var fieldOfTechnologyOptions = [{
  name: '1. Natural and formal sciences',
  children: [{
    name: 'Mathematics',
    children: ['1.01.01 - Pure mathematics', '1.01.02 - Applied mathematics', '1.01.03 - Statistics and probability']
  }, {
    name: 'Computer and information sciences',
    children: ['1.02.01 - Computer sciences', '1.02.02 - Information technology and bioinformatics']
  }, {
    name: 'Physical sciences',
    children: ['1.03.01 - Atomic, molecular, and chemical physics', '1.03.02 - Interaction with radiation', '1.03.03 - Magnetic resonances', '1.03.04 - Condensed matter physics', '1.03.05 - Solid state physics and superconductivity', '1.03.06 - Particles and fields physics', '1.03.07 - Nuclear physics', '1.03.08 - Fluids and plasma physics (including surface physics)', '1.03.09 - Optics (including laser optics and quantum optics)', '1.03.10 - Acoustics', '1.03.11 - Astronomy (including astrophysics space science)']
  }, {
    name: 'Chemical sciences',
    children: ['1.04.01 - Organic chemistry', '1.04.02 - Inorganic and nuclear chemistry', '1.04.03 - Physical chemistry, polymer science, and plastics', '1.04.04 - Electrochemistry (dry cells, batteries, fuel cells, metal corrosion, electrolysis)', '1.04.05 - Colloid chemistry', '1.04.06 - Analytical chemistry']
  }, {
    name: 'Earth and related Environmental sciences',
    children: ['1.05.01 - Geosciences, multidisciplinary', '1.05.02 - Mineralogy and palaeontology', '1.05.03 - Geochemistry and geophysics', '1.05.04 - Physical geography', '1.05.05 - Geology and volcanology', '1.05.06 - Environmental sciences', '1.05.07 - Meteorology, atmospheric sciences, and climatic research', '1.05.08 - Oceanography, hydrology, and water resources']
  }, {
    name: 'Biological sciences',
    children: ['1.06.01 - Cell biology, microbiology, and virology', '1.06.02 - Biochemistry, molecular biology, and Biochemical research', '1.06.03 - Mycology', '1.06.04 - Biophysics', '1.06.05 - Genetics and heredity (medical genetics under code 3)', '1.06.06 - Reproductive biology (medical aspects under code 3)', '1.06.07 - Developmental biology', '1.06.08 - Plant sciences and botany', '1.06.09 - Zoology, ornithology, entomology, and behavioural sciences biology', '1.06.10 - Marine biology, freshwater biology, and limnology', '1.06.11 - Ecology and biodiversity conservation', '1.06.12 - Biology (theoretical, thermal, cryobiology, biological rhythm)', '1.06.13 - Evolutionary biology']
  }, {
    name: 'Other natural sciences',
    children: ['1.07.01 - Other natural sciences']
  }]
}, {
  name: '2. Engineering and technology',
  children: [{
    name: 'Civil engineering',
    children: ['2.01.01 - Civil engineering', '2.01.02 - Architecture engineering', '2.01.03 - Municipal and structural engineering', '2.01.04 - Transport engineering']
  }, {
    name: 'Electrical engineering, Electronic engineering, and Information technology',
    children: ['2.02.01 - Electrical and electronic engineering', '2.02.02 - Robotics and automatic control', '2.02.03 - Micro-electronics', '2.02.04 - Semiconductors', '2.02.05 - Automation and control systems', '2.02.06 - Communication engineering and systems', '2.02.07 - Telecommunications', '2.02.08 - Computer hardware and architecture', '2.02.09 - Software engineering and technology']
  }, {
    name: 'Mechanical engineering',
    children: ['2.03.01 - Mechanical engineering', '2.03.02 - Applied mechanics', '2.03.03 - Thermodynamics', '2.03.04 - Aerospace engineering', '2.03.05 - Nuclear related engineering (nuclear physics under 1.03.07)', '2.03.06 - Acoustical engineering', '2.03.07 - Reliability analysis and non-destructive testing', '2.03.08 - Automotive and transportation engineering and manufacturing', '2.03.09 - Tooling, machinery, and equipment engineering and manufacturing', '2.03.10 - Heating, ventilation, and Air conditioning engineering and manufacturing']
  }, {
    name: 'Chemical engineering',
    children: ['2.04.01 - Chemical engineering (plants, products)', '2.04.02 - Chemical process engineering']
  }, {
    name: 'Materials engineering',
    children: ['2.05.01 - Materials engineering and metallurgy', '2.05.02 - Ceramics', '2.05.03 - Coating and films (including packaging and printing)', '2.05.04 - Plastics, Rubber, and Composites (including laminates and reinforced plastics)', '2.05.05 - Paper and wood and textiles', '2.05.06 - Construction materials (organic and inorganic)']
  }, {
    name: 'Medical engineering',
    children: ['2.06.01 - Medical and biomedical engineering', '2.06.02 - Medical laboratory technology (biomaterials under 2.09.05)']
  }, {
    name: 'Environmental engineering',
    children: ['2.07.01 - Environmental and geological engineering', '2.07.02 - Petroleum engineering (fuel, oils)', '2.07.03 - Energy and fuels', '2.07.04 - Remote sensing', '2.07.05 - Mining and mineral processing', '2.07.06 - Marine engineering, sea vessels, and ocean engineering']
  }, {
    name: 'Environmental biotechnology',
    children: ['2.08.01 - Environmental biotechnology', '2.08.02 - Bioremediation', '2.08.03 - Diagnostic biotechnologies in environmental management (DNA chips and biosensing devices)']
  }, {
    name: 'Industrial biotechnology',
    children: ['2.09.01 - Industrial biotechnology', '2.09.02 - Bioprocessing technologies', '2.09.03 - Biocatalysis and fermentation', '2.09.04 - Bioproducts (products that are manufactured using biological material as feedstock)', '2.09.05 - Biomaterials (bioplastics, biofuels, bioderived bulk and fine chemicals, bio-derived materials)']
  }, {
    name: 'Nano-technology',
    children: ['2.10.01 - Nano-materials (production and properties)', '2.10.02 - Nano-processes (applications on nano-scale)']
  }, {
    name: 'Other engineering and technologies',
    children: ['2.11.01 - Food and beverages', '2.11.02 - Oenology', '2.11.03 - Other engineering and technologies']
  }]
}, {
  name: '3. Medical and Health sciences',
  children: [{
    name: 'Basic medicine',
    children: ['3.01.01 - Anatomy and morphology (plant science under 1.06.08)', '3.01.02 - Human genetics', '3.01.03 - Immunology', '3.01.04 - Neurosciences', '3.01.05 - Pharmacology and pharmacy and medicinal chemistry', '3.01.06 - Toxicology', '3.01.07 - Physiology and cytology', '3.01.08 - Pathology']
  }, {
    name: 'Clinical medicine',
    children: ['3.02.01 - Andrology', '3.02.02 - Obstetrics and gynaecology', '3.02.03 - Paediatrics', '3.02.04 - Cardiac and cardiovascular systems', '3.02.05 - Haematology', '3.02.06 - Anaesthesiology', '3.02.07 - Orthopaedics', '3.02.08 - Radiology and nuclear medicine', '3.02.09 - Dentistry, oral surgery, and medicine', '3.02.10 - Dermatology, venereal diseases, and allergy', '3.02.11 - Rheumatology', '3.02.12 - Endocrinology and metabolism and gastroenterology', '3.02.13 - Urology and nephrology', '3.02.14 - Oncology']
  }, {
    name: 'Health sciences',
    children: ['3.03.01 - Health care sciences and nursing', '3.03.02 - Nutrition and dietetics', '3.03.03 - Parasitology', '3.03.04 - Infectious diseases and epidemiology', '3.03.05 - Occupational health']
  }, {
    name: 'Medical biotechnology',
    children: ['3.04.01 - Health-related biotechnology', '3.04.02 - Technologies involving the manipulation of cells, tissues, organs, or the whole organism', '3.04.03 - Technologies involving identifying the functioning of DNA, proteins, and enzymes', '3.04.04 - Pharmacogenomics, gene-based therapeutics', '3.04.05 - Biomaterials (related to medical implants, devices, sensors)']
  }, {
    name: 'Other medical sciences',
    children: ['3.05.01 - Forensic science', '3.05.02 - Other medical sciences']
  }]
}, {
  name: '4. Agricultural sciences',
  children: [{
    name: 'Agriculture, Forestry, and Fisheries Veterinary science',
    children: ['4.01.01 - Agriculture', '4.01.02 - Forestry', '4.01.03 - Fisheries and Aquaculture', '4.01.04 - Soil science', '4.01.05 - Horticulture', '4.01.06 - Viticulture', '4.01.07 - Agronomy', '4.01.08 - Plant breeding and plant protection (Agricultural biotechnology under 4.04.01)']
  }, {
    name: 'Animal and dairy science',
    children: ['4.02.01 - Animal and Dairy science', '4.02.02 - Animal husbandry (animal biotechnology under 4.04.01)']
  }, {
    name: 'Veterinary science',
    children: ['4.03.01 - Veterinary science (all)']
  }, {
    name: 'Agricultural biotechnology',
    children: ['4.04.01 - Agricultural biotechnology and food biotechnology', '4.04.02 - Genetically Modified (GM) organism technology and livestock cloning', '4.04.03 - Diagnostics (DNA chips and biosensing devices)', '4.04.04 - Biomass feedstock production technologies', '4.04.05 - Biopharming']
  }, {
    name: 'Other agricultural sciences',
    children: ['4.05.01 - Other agricultural sciences']
  }]
}];
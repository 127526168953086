import { api } from '@rd-web-markets/shared/dist/services/service';
export var DocumentFileService = {
  upload: function upload(id, body) {
    return api.postFormData("/document_types/".concat(id, "/document_files"), body);
  },
  update: function update(id, documentId, body) {
    return api.update("/document_types/".concat(id, "/document_files/").concat(documentId), body);
  },
  delete: function _delete(id, documentId) {
    return api.delete("/document_types/".concat(id, "/document_files/").concat(documentId));
  }
};
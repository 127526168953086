import { CROSS_MARKET_EMAIL_DYNAMIC_FIELDS } from "@rd-web-markets/shared/dist/util/constants";
export var UTR_TEXT = 'UTR';
export var SPRINT_CLAIM_NAME = 'CA_Sprint_Claim';
export var TEMPLATE_PAGE_TITLE = 'Claim Template Listing';
export var CLAIM_GROUP_ENGAGEMENT_TITLE = 'contacts_email_kick_off_meeting_organization';
export var COMPANY_REQUIRES_COMPANIES_HOUSE_INFO = false;
export var CLAIM_SELECTION_ALLOWS_SLICING_MULTI_YEAR_CLAIMS = false;
export var EDITOR_CLAIM_SPECIFIC_DYNAMIC_FIELDS = [{
  field: 'accounting_period_end_date',
  title: 'Accounting Period End Date',
  editableInModal: true
}, {
  field: 'accounting_period_start_date',
  title: 'Accounting Period Start Date',
  editableInModal: true
}, {
  field: 'total_qualifying_rnd_expenditure',
  title: 'Total Qualifying RND Expenditure'
}];
export var EDITOR_CLAIM_GROUP_DYNAMIC_FIELDS = [{
  field: 'company_overview_rnd_context',
  title: 'Company Overview RND Context'
}, {
  field: 'contact_to_collaborate_on_rnd',
  title: 'Contact To Collaborate',
  editableInModal: true
}];
export var EDITOR_CLAIM_PROJECT_FIELDS = [];
export var NEW_COMPANY_NEEDS_COMPANY_NUMBER = false;
export var CLAIM_GROUP_DEFAULT_PARAMS = {
  consultants_attributes: [],
  project_methodology: 'employee_basis',
  rnd_collaborate_contacts_attributes: [],
  has_overdue_taxes: false,
  has_staff_involved_not_on_payroll: false,
  accounts_provided: false,
  tax_computations_provided: false,
  development_costs_capitalised: false,
  contracted_by_third_party: null,
  received_grants_or_subsidies: null,
  reimburses_staff_expenses: false,
  purchased_company_cars: false,
  has_patents: false
};
export var CLAIM_GROUP_EDIT_FORM_FIELDS = {
  showSicCodesString: true,
  showProjectMehotdology: true,
  showOverdueTaxesSection: true,
  showOverdueTaxesQuestion: false,
  showDoesCompanyReimburseStaffExpenses: false,
  showDoesCompanyPayCarAllowance: false
};
export var SHOULD_SHOW_COST_METHODOLOGY = false;
export var SHOULD_SHOW_OVERDUE_TAXES = true;
export var CLIENT_CONTACT_TYPES = ['Client Payables', 'Client Sponsor', 'Financial Contact', 'Technical Contact'];
export var EXTERNAL_CONTACT_TYPES = ['Financial Auditor', 'Technical Auditor'];
export var CLAIM_GROUP_EDIT_PAGE_SHOW_YEAR_END_DROPDOWN = false;
export var CLAIM_GROUP_SHOW_ALL_PROJECT = false;
export var APP_FOOTER_TEXT = 'R&D Tax Credits Portal by Ayming';
export var APP_RELEASE_VERSION = "Release ".concat(process.env.APP_VERSION);
export var LOCALE = 'en-CA';
export var ENGINE_NAME = 'CaSection';
export var EMAIL_TEMPLATE_DYNAMIC_FIELDS = {
  email_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  client_cost_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  claim_schedule_ics_event_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS,
  self_declaration_template: CROSS_MARKET_EMAIL_DYNAMIC_FIELDS
};
export var SHOW_CLAIM_GROUP_TASKS = true;
export var SHOW_COMPANY_CLAIM_GROUP_EDIT_FORM_FIELDS = true;
export var MARKET_SPECIFIC_RD_OR_4 = 'R&D';
export var MARKET_SPECIFIC_RD_OR_4_MENU = "".concat(MARKET_SPECIFIC_RD_OR_4, " Technical");
export var TEMPLATE_HEADER_TITLE = {
  email_template: 'Configure Kick Off Template',
  client_cost_template: 'Configure Client Cost Template',
  claim_schedule_ics_event_template: 'Configure Ics Event Template',
  self_declaration_template: 'Configure Self - Declaration Email Template',
  kick_off: 'Configure Kick Off Template'
};
export var REPORT_SAVE_PARAM = '';
export var TASK_TITLE = 'claim_group_tasks';
export var LOCAL_LANGUAGES = ['en', 'fr'];
export var REPORT_TYPE_INCLUDE_SECTION_COLUMN_NAME = {
  technical: 'include_in_technical_report'
};
export var CURRENCY_SYMBOL = '$';
export var SIDEBAR_MENU_ITEMS = [{
  text: 'project_overview',
  link: 'project_overview'
}, {
  text: 'edit_claim_info',
  link: 'edit/general'
}, {
  text: 'engagement_team',
  link: 'engagement_team'
}, {
  text: 'intro_email',
  link: 'intro_email'
}, {
  text: 'claim_schedule',
  link: 'claim_schedule'
}, {
  text: 'schedule_email',
  link: 'schedule_email'
}, {
  text: 'claim_costs',
  link: 'claim_costs',
  subMenu: true,
  showLastFourChars: false
}, {
  text: 'r_d_technical',
  link: 'technical_section'
}, {
  text: 'report_template',
  link: 'report_template'
}, {
  text: 'manager_technical_review',
  link: 'manager_review'
}, {
  text: 'client_technical_review',
  link: 'client_review'
}, {
  text: 'complete_claim',
  link: 'complete_claim'
}];
export var SIDEBAR_NAV_ADMIN_LINKS = [{
  text: 'Companies',
  link: 'companies'
}, {
  text: 'Claims',
  link: 'claims',
  subMenu: [{
    text: 'My Claims',
    link: 'my_claim_groups'
  }, {
    text: 'All Claims',
    link: 'all_claim_groups'
  }]
}, {
  text: 'Users',
  link: 'users'
}, {
  text: 'Templates',
  link: 'templates',
  subMenu: [{
    text: 'Claim Templates',
    link: 'claim_template'
  }, {
    text: 'Project Description (T661)',
    link: 'report_templates'
  }, {
    text: 'Kick Off Email Template',
    link: 'kickoff_email_template'
  }, {
    text: 'Claim Schedule Ics Event Template',
    link: 'claim_schedule_ics_event_template'
  }, {
    text: 'Client Cost Template',
    link: 'client_cost_template'
  }, {
    text: 'Questionnaires',
    link: 'questionnaires'
  }]
}, {
  text: 'Translations',
  link: 'translations'
}];
export var SIDEBAR_NAV_CLIENT_LINKS = [{
  text: 'All Claims',
  link: ''
}];
export var SIDEBAR_NAV_ACCOUNTANT_LINKS = [{
  text: 'All Claims',
  link: ''
}];
export var SIDEBAR_NAV_CONSULTANT_LINKS = [{
  text: 'Companies',
  link: 'companies'
}, {
  text: 'Claims',
  link: 'my_claims'
}];
export var SIDEBAR_NAV_SUPER_CONSULTANT_LINKS = [{
  text: 'Companies',
  link: 'companies'
}, {
  text: 'Claims',
  link: 'claims',
  subMenu: [{
    text: 'My Claims',
    link: 'my_claim_groups'
  }, {
    text: 'All Claims',
    link: 'all_claim_groups'
  }]
}];
export var SIDEBAR_CLAIM_COSTS_LINKS = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/summary_expenses'
}, {
  text: 'Client Cost Email',
  link: 'financial_details/email'
}, {
  text: 'Cost Details',
  link: 'edit/general'
}, {
  text: 'Manager Cost Review',
  link: 'financial_details/manager_review'
}];
export var SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER = [{
  text: 'Upload/Download Cost Template',
  link: 'financial_details/upload'
}, {
  text: 'Cost Categories',
  link: 'financial_details/summary_expenses'
}];
export var SIDEBAR_CLAIM_COSTS_LINKS_ACCOUNTANT = [{
  text: 'Cost Categories',
  link: 'financial_details/general'
}];
export var SIDEBAR_TECHNICAL_SECTION_LINKS = [{
  text: 'company_info',
  link: 'company_info'
}, {
  text: 'project_reports',
  link: 'project_reports',
  subMenu: true
}, {
  text: 'documents',
  link: 'technical_uploads'
}];
export var SIDEBAR_CLAIM_OVERVIEW_LINKS_CUSTOMER = [{
  text: 'Claim Cost',
  link: 'claim_cost',
  subMenu: true
}, {
  text: 'R&D Technical',
  link: 'technical_section'
}];
export var SIDEBAR_NAV_RND_REPORT_PAGE_CLIENT_LINKS = {
  link: 'companies',
  text: 'Companies'
};
export var CURRENCY_FORMATTER = new Intl.NumberFormat(LOCALE);
export var PERCENTAGE_FORMATTER = new Intl.NumberFormat(LOCALE, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
});
export var ENGAGEMENT_TEAM_ROLES = [{
  value: 'client_sponsor'
}, {
  value: 'financial_contact'
}, {
  value: 'technical_contact'
}, {
  value: 'other_stakeholder'
}, {
  value: 'auditor'
}];
export var CLIENT_RND_EDIT_DYNAMIC_FIELD = true;
export var CLIENT_RND_SELECT_PROJECT_POPOVER = true;
export var SUPPORT_EMAIL_ADDRESS = 'ca.app.express.support@ayming.com';
export var ATTACHMENT_OPTIONS = {
  attachCostTemplate: false,
  technicalTemplateField: false,
  includeTechnicalUploadField: false,
  includeCostTemplateLink: false
};
export var SHOW_DOWNLOAD_TEMPLATE_BUTTON = false;
export var TEMPLATE_SELECT_MODEL_NAME = 'report_templates';
export var PROJECT_FIELD_MODEL_NAME = 'project_description_fields';
export var SHOULD_CREATE_REPORT = false;
export var modifyReportTemplateCategoriesForProject = [0, 1]; //at index 0, removes 1 element
export var CONSULTANT_ROLE_NAME = 'consultant';
export var SHOW_DEPARTMENT_FOR_USER_FORM = false;
export var SHOULD_PROJECT_POPOVER_EXCLUDE_OTHER_PROJECTS = true;
export var TINYMCE_MARKET_REPORT_MODULES = ['styles_formatter', 'track_changes', 'comments', 'resize_image_when_added'];
export var TINYMCE_MARKET_EMAIL_MODULES = ['styles_formatter', 'resize_image_when_added'];
export var TINYMCE_MARKET_OTHER_MODULES = ['styles_formatter', 'track_changes', 'comments', 'resize_image_when_added'];
export var SHOW_COST_MENU_IN_MASTER = false;
export var SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW = true;
export var FINANCIAL_SECTION_GENERAL_LINK = 'summary_expenses';
export var SHOULD_AUTOSAVE = true;
export var SHOULD_PRESET_COMPANY_CLAIMGROUP_FORM_FILEDS = true;
export var SHOULD_SHOW_ADD_USER_BUTTON_FOR_ADMINS = true;
export var SHOULD_SHOW_ADMIN_OPTION_FOR_CONSULTANTS = true;
export var IS_PROJECT_DATE_SHORT_FORMAT = true;
export var SHOULD_SHOW_DATE_IN_SUMMARY_SECTION = false;
export var RATES_SCHEME = {
  'NI Rates': ['rate'],
  'Corporation Tax Rates': ['rate'],
  'Enhancement Rates': ['rate'],
  'SME Scheme Rates': ['enhanced_deduction', 'payable_credit'],
  'Credit Rates': ['rate']
};
export var SHOULD_ADD_REPORT_TEMPLATE_SEARCH = false;
export var SHOULD_SHOW_TEMPLATE_TITLE = false;
export var SHOW_SEND_CLAIM_BUTTON = false;
export var PROJECT_REPORT_DATE_PICKER_TYPE = 'month';
export var SHOW_CLAIM_GROUP_PROJECT_ELSTER = false;
export var SHOW_PROJECT_TEMPLATE_SELECT = true;
export var PROJECT_OVERVIEW_TABLE = {
  headings: ['business_unit', 'name', 'project_category', 'total_cost', 'total_qe', 'documents', 'assessment_status', 'cost', 'project_status', ''],
  columns: ['bussiness_units', 'project_name', 'category', 'total_cost', 'total_qe', 'documents', 'assessment_status', 'cost', 'completion_status', '']
};
export var SHOW_QUESTIONNAIRES_SELECT_SEPARATELY = false;
export var SHOW_REGULAR_REALTIME_DASHBOARD = false;
export var SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE = true;
export var SHOULD_SHOW_ADD_CLAIM_BUTTON_IN_CLAIM_GROUP_SIDEBAR = true;
export var SHOULD_SHOW_ADD_COMPANY_BUTTON = true;